.data-display {
  display: flex;
  flex-direction: column;

  small {
    font-size: 12px;
    opacity: 0.5;
  }

  strong {
    font-size: 16px;
  }
}