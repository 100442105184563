@import './CommonColors.scss';

/* ant design */
  /* Table defaults */
  .ant-table-thead > tr.ant-table-row-hover > td,
  .ant-table-tbody > tr.ant-table-row-hover > td,
  .ant-table-thead > tr:hover > td,
  .ant-table-tbody > tr:hover > td,
  tr:hover > td {
      background: transparent !important;
  }
  /* inputs */
  .ant-form-item {
    margin-bottom: 14px !important;
  }
  input {
    box-shadow: none !important;
    outline:0 !important;
  }
  input:hover, input:focus, input:active {
    border-color: #D6664A !important;
  }
  /* buttons */
  btn, btn-active, button, button:hover, button:focus, button:active, button:visited {
    box-shadow: none !important;
    outline:0 !important;
  }

.noStepper input[type=number]::-webkit-inner-spin-button,
.noStepper input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Recaptcha */
.grecaptcha-badge {
	visibility: collapse;
}

.ant-notification {
  z-index: 9999999999 !important;
}

.ant-form-item-explain {
  text-align: left;
}

.ant-layout-content {
  background: #F1F1F1 !important;
}

.ant-page-header-heading-title {
  font-size: 28px !important;
}

.steps-content {
  // margin-top: 20px !important;
}

.ant-table-content {
  table {
    tbody {
      tr:hover td {
        background: lighten(map-get($shades-of-red, main), 40%) !important;
      }
    }
  }
}

// Simualate h5/h6 from bootstrap
h5 {
  font-size: 20px !important;
}
h6 {
  font-size: 16px !important;
}

// picker disabled

.ant-picker-disabled {
  .ant-picker-suffix {
    display: none;
  }
}

.ant-input-number-disabled {
  border-color: white !important;
  .ant-input-number-input-wrap {
    input {
      background-color: white;
    }
  } 
}
.ant-select-borderless.ant-select-disabled {
  .ant-select-arrow {
    display: none;
  }
}

/* Drawer */
.smallPaddingDrawer {
  .ant-drawer-body {
    padding: 12px 24px 12px 24px;
  }
}