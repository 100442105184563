.adminACLCollapseSection .ant-collapse-content-box {
  padding: 0px 10px 0px 10px !important;
}
.adminACLRow {
  height: 20px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.adminACLRowDivider {
  margin: 0px !important;
  padding: 0px !important;
}
.adminACLRowRadioGroup {
  margin-bottom: 10px !important;
}