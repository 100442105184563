.setupContainer {
  height: 100% !important;
  min-height: calc(100vh - 60px) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.setupBoxContainer {
  border:  1px solid #dadce0;
  border-radius: 8px;
  -webkit-box-shadow: 3px 4px 15px -4px rgba(0,0,0,0.43);
  -moz-box-shadow: 3px 4px 15px -4px rgba(0,0,0,0.43);
  box-shadow: 3px 4px 15px -4px rgba(0,0,0,0.43);
  min-width: 400px;
  margin-bottom: 20px;
}
.setupBox {
  margin: 30px 30px 20px 30px;
}
.setupBoxTitle {
  font-weight: 600;
}
.setupBoxDescription {
  margin-bottom: 15px;
  font-weight: 400;
}


.setupForm {
  margin-top: 20px !important;
}
.setupButton {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px !important;
  font-weight: 500 !important;
  width: 250px !important;
  height: 40px !important;
}
