.settingsCard {
  background-color: #fff;
  border: 1px solid #D7503F;
  border-radius: 5px;
  padding: 20px;
  cursor: pointer;
  position: relative;
  transition: all 0.15s ease-in;
  display: flex;

  &:hover {
    background-color: #D7503F;
    border-color: #D7503F;
    box-shadow: 0 0 0 2px #D7503F inset,
                0 0 0 4px rgba(255, 255, 255, 1) inset;

    h3, span {
      color: #fff;
    }
  }

  .settingsCardInfo {
    margin-left: 10px;
  }

  h3 {
    transition: all 0.15s ease-in;
    font-size: 20px;
  }

  span {
    transition: all 0.15s ease-in;
    font-size: 14px;
    color: #666;
  }

  .settingsCardIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    
    svg {
      color: #fff;
    }
  }
}