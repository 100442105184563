.authorizationContainer {
  height: 100% !important;
  min-height: calc(100vh - 60px) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.authorizationBoxContainer {
  border:  1px solid #dadce0;
  border-radius: 8px;
  -webkit-box-shadow: 3px 4px 15px -4px rgba(0,0,0,0.43);
  -moz-box-shadow: 3px 4px 15px -4px rgba(0,0,0,0.43);
  box-shadow: 3px 4px 15px -4px rgba(0,0,0,0.43);
  min-width: 340px;
  max-width: 340px;
}
.authorizationBox {
  margin: 15px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.authorizationBoxTitle {
  font-weight: 600;
}
.authorizationBoxDescription {
  margin-bottom: 15px;
  font-weight: 400;
}

.loginForm {
  width: 100%;
}
.loginFormSignup {
  float: left;
  margin-top: 15px;
  color: rgba(0, 0, 0, 0.65) !important;
}
.loginFormRemeberMe {
  float: left;
}
.loginFormForgot {
  float: right;
  margin-top: 15px;
  color: rgba(0, 0, 0, 0.65) !important;
}
.loginFormLoginButton {
  width: 100%;
}

/* Change pass modal */
.changePasswordTooltipIcon {
  position: absolute !important;
  top: 60% !important;
  right: -10px !important;
  font-size: 19px !important;
  color: #4B5660 !important;
}
.passwordTooltip {

}
.ant-popover {
  z-index: 9999999 !important;
}
