.memberReadOnlyForm {
  // .ant-picker-disabled {
  // .ant-picker-suffix {
  //   display: none;
  //   }
  // }

  // .ant-input-number-disabled {
  //   border-color: white !important;
  //   .ant-input-number-input-wrap {
  //     input {
  //       background-color: white;
  //     }
  //   } 
  // }
  .ant-row .ant-form-item {
    margin-bottom: 0px !important;
  }
  .ant-select-borderless.ant-select-disabled {
    .ant-select-selector {
      color: black !important;
    }
  }
  .ant-input[disabled], .ant-input-number-disabled, .ant-picker-input > input[disabled] {
    color: black !important;
  }

  .monthToDateBox {
    .ant-input-number-disabled {
      border-color: #F9F9F9 !important;
    }
    input {
      background-color: #F9F9F9 !important;
    }
  }
}