.application-form-container {
  width: 100%;
  padding: 32px;
}

.application-form-content {
  max-width: 1000px;
  margin: 0 auto;
}

.signature-container {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .divider {
    width: 100%;
    height: 2px;
    background: #333;
    border-radius: 1px;
  }

  .signature-area {
    width: 100%;
    height: 40px;
    border: 1px dashed #333;
    margin-bottom: -1px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #aaa;
    cursor: pointer;
    transition: 0.2s ease-in;

    &.filled {
      border-color: #ccc;
    }

    &:hover {
      border-color: #D7503F;
    }

    img {
      height: 30px;
    }
  }
}

.alert-content {
  background: #D6664A;
  border: 3px solid #D7503F;
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 16px;
  color: #fff;
  margin-bottom: 50px;

  h3 {
    color: #fff;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    
    img {
      margin-bottom: 24px;
    }

    h3 {
      text-align: center;
    }
  }
}